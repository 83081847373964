var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading,"variant":"transparent","opacity":"0.99","blur":"5px","rounded":"sm"}},[_c('h4',[_vm._v("ค่ายเกมส์")]),_c('b-card',[_c('h4',[_vm._v("Slot")]),_c('b-row',{attrs:{"match-height":""}},_vm._l((_vm.products.filter(function (item) { return item.category === 'EGAMES'; })),function(item){return _c('b-col',{attrs:{"sm":"4","md":"3","lg":"2"}},[_c('b-card',{staticClass:"border overflow-hidden",staticStyle:{"border-radius":"16px"},attrs:{"no-body":""}},[(item.img_url.includes('mp4'))?_c('video',{staticClass:"w-100 h-100",staticStyle:{"pointer-events":"none","width":"100%","height":"100%","object-fit":"cover","top":"0","left":"0"},attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":("" + (item.img_url)),"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_c('img',{staticClass:"w-100 h-100",staticStyle:{"object-fit":"cover"},attrs:{"src":item.img_url.startsWith('http')
                ? item.img_url
                : ("" + (item.img_url))}}),_c('div',{staticClass:"mt-1 text-truncate px-1 pb-1"},[_c('h6',[_vm._v(_vm._s(item.productName))]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","value":1,"unchecked-value":0},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)])],1)}),1)],1),_c('b-card',[_c('h4',[_vm._v("CASINO")]),_c('b-row',{attrs:{"match-height":""}},_vm._l((_vm.products.filter(
          function (item) { return item.category === 'LIVECASINO'; }
        )),function(item){return _c('b-col',{attrs:{"sm":"4","md":"3","lg":"2"}},[_c('b-card',{staticClass:"border overflow-hidden",staticStyle:{"border-radius":"16px"},attrs:{"no-body":""}},[(item.img_url.includes('mp4'))?_c('video',{staticClass:"w-100 h-100",staticStyle:{"pointer-events":"none","width":"100%","height":"100%","object-fit":"cover","top":"0","left":"0"},attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":("" + (item.img_url)),"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_c('img',{staticClass:"w-100 h-100",staticStyle:{"object-fit":"cover"},attrs:{"src":item.img_url.startsWith('http')
                ? item.img_url
                : ("" + (item.img_url))}}),_c('div',{staticClass:"mt-1 text-truncate px-1 pb-1"},[_c('h6',[_vm._v(_vm._s(item.productName))]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","value":1,"unchecked-value":0},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)])],1)}),1)],1),_c('b-card',[_c('h4',[_vm._v("SPORT")]),_c('b-row',{attrs:{"match-height":""}},_vm._l((_vm.products.filter(function (item) { return item.category === 'SPORT'; })),function(item){return _c('b-col',{attrs:{"sm":"4","md":"3","lg":"2"}},[_c('b-card',{staticClass:"border overflow-hidden",staticStyle:{"border-radius":"16px"},attrs:{"no-body":""}},[(item.img_url.includes('mp4'))?_c('video',{staticClass:"w-100 h-100",staticStyle:{"pointer-events":"none","width":"100%","height":"100%","object-fit":"cover","top":"0","left":"0"},attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":("" + (item.img_url)),"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_c('img',{staticClass:"w-100 h-100",staticStyle:{"object-fit":"cover"},attrs:{"src":item.img_url.startsWith('http')
                ? item.img_url
                : ("" + (item.img_url))}}),_c('div',{staticClass:"mt-1 text-truncate px-1 pb-1"},[_c('h6',[_vm._v(_vm._s(item.productName))]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","value":1,"unchecked-value":0},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }